import Big from 'big.js';
import { toDate } from 'date-fns';
import { Temporal } from 'temporal-polyfill';
import { z } from 'zod';

export const bigNumberSchema = z.preprocess(
  (val) => {
    if (typeof val === 'string') {
      try {
        return new Big(val);
      } catch {
        return undefined;
      }
    }

    if (val instanceof Big) {
      return val;
    }

    return undefined;
  },
  z.custom<Big>((val) => val instanceof Big, 'Cannot create Big number from input.'),
) as z.ZodEffects<z.ZodType<Big.Big, z.ZodTypeDef, Big.Big>, Big.Big, Big.Big>;

const datetimeOffset = z.string().datetime({ offset: true });

export const zodIsoDate = z.preprocess((val) => {
  const isDateTime = datetimeOffset.safeParse(val);

  if (!isDateTime.success) {
    return undefined;
  }

  return toDate(isDateTime.data).toISOString();
}, z.string().datetime()) as z.ZodEffects<z.ZodString, string, string>;

export const zodInstant = z.preprocess(
  (val) => {
    if (typeof val === 'string') {
      try {
        return Temporal.Instant.from(val);
      } catch {
        return undefined;
      }
    }

    if (val instanceof Temporal.Instant) {
      return val;
    }

    return undefined;
  },
  z.custom<Temporal.Instant>((val) => val instanceof Temporal.Instant, 'Cannot create Instant from input.'),
) as z.ZodEffects<z.ZodType<Temporal.Instant, z.ZodTypeDef, Temporal.Instant>, Temporal.Instant, Temporal.Instant>;

export const zodPlainDate = z.preprocess(
  (val) => {
    if (typeof val === 'string') {
      try {
        return Temporal.PlainDate.from(val);
      } catch {
        return undefined;
      }
    }

    if (val instanceof Temporal.PlainDate) {
      return val;
    }

    return undefined;
  },
  z.custom<Temporal.PlainDate>((val) => val instanceof Temporal.PlainDate, 'Cannot create PlainDate from input.'),
) as z.ZodEffects<
  z.ZodType<Temporal.PlainDate, z.ZodTypeDef, Temporal.PlainDate>,
  Temporal.PlainDate,
  Temporal.PlainDate
>;

export const zodPlainYearMonth = z.preprocess(
  (val) => {
    if (typeof val === 'string') {
      try {
        return Temporal.PlainYearMonth.from(val);
      } catch {
        return undefined;
      }
    }

    if (val instanceof Temporal.PlainYearMonth) {
      return val;
    }

    return undefined;
  },
  z.custom<Temporal.PlainYearMonth>(
    (val) => val instanceof Temporal.PlainYearMonth,
    'Cannot create PlainYearMonth from input.',
  ),
) as z.ZodEffects<
  z.ZodType<Temporal.PlainYearMonth, z.ZodTypeDef, Temporal.PlainYearMonth>,
  Temporal.PlainYearMonth,
  Temporal.PlainYearMonth
>;
